import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: 'home', component: () => import('@/views/homeView.vue'), meta: {title: '首页', requireAuth: false}},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

// 路由前向限制
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('TOKEN');

  if(to.meta.requireAuth) {
    if(token) {
      next();
    } else {
      next({
        path: '/',
        // query: { redirect: to.fullPath } // 记住要访问的页面路由地址，后面登录后跳转会用到该值
      })
    }
  } else {
    next();
  }
})
